import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { stateContext } from "../../../../states/context";
import {
  fetchDCSData,
  populatedDrodownContent,
} from "../../../../services/apiService";
import Loader from "../../../common/loader";
import logo from "../../../../assets/Images/assist1.png";
import BackButton from "../../../common/backButton";
import ScreenInfo from "../../../common/info";
import Button from "../../../common/button";
import Input from "../../../common/input";
import Textarea from "../../../common/textarea";

const DocumentSearch = () => {
  const history = useHistory();
  const { state, setState } = useContext(stateContext);
  const [loading, setLoading] = useState(false);
  const [fetchingResponse, setFetchingResponse] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [search, setSearch] = useState("");

  const handleChange = (e) => {
    setSelectedFile(e);
  };
  const handleBack = () => {
    setState((s) => ({ ...s, dcsSearchResponse: null }));
    history.push("/research/dcs/upload");
  };

  const handleSearch = () => {
    if (search === "" || selectedFile == null) {
      return toast.info(
        search === ""
          ? "Please enter query to search"
          : "Please select file for search"
      );
    }
    setFetchingResponse(true);
    fetchDCSData(search, selectedFile?.id)
      .then((res) => {
        setState((s) => ({
          ...s,
          dcsSearchResponse: { res: res, search: search },
        }));
        setFetchingResponse(false);
      })
      .catch((err) => {
        console.log(err);
        setFetchingResponse(false);
      });
    setSearch("");
  };
  useEffect(() => {
    setLoading(true);
    populatedDrodownContent()
      .then((res) => {
        const dropdownOptions = res?.map((_) => {
          const splitArray = _?.file_path?.split("/");
          const filename = splitArray?.reverse()[0];
          const output = {
            label: filename,
            value: filename,
            id: _?.file_id,
            title: _?.title,
            description: _?.description,
            tags: _?.tags
          };
          return output;
        });
        setState((s) => ({
          ...s,
          uploadedFileContents: dropdownOptions?.length ? [{
            "id": "*",
            "label": "Select All",
            "value": "*"
          }, ...dropdownOptions] : [],
        }));
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [setState]);

  let options = state?.uploadedFileContents ? state?.uploadedFileContents : []

  return (
    <section className="main main-summary m-4">
      <BackButton path={`/research/dcs/upload`} state={`dcsSearchResponse`} />
      <div className="summary-inner">
        <div className="summary-profile">
          <ScreenInfo img={logo} text="Hey I am DCS assistant" />
        </div>
        <div className="summart-form">
          <div>
            <Select
              hideSelectedOptions={false}
              value={selectedFile}
              options={options}
              onChange={handleChange}
              placeholder={
                loading ? (
                  <Loader
                    size="sm"
                    variant="secondary"
                    dimension="row"
                    message="Loading..."
                  />
                ) : (
                  "Select a document"
                )
              }
              isDisabled={loading}
              className="select-form dropdown"
              classNamePrefix="react-select"
              isSearchable={true}
            />
          </div>
          <div className="search-label">
            <label>Ask anything about the document</label>
            <div className="search document-search document-search-custom custom-search">
              <Input
                disabled={loading || fetchingResponse}
                type="text"
                placeholder="Search query from selected document"
                name="search"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          {selectedFile?.value != "*" && 
          <div className="info-on-select">
            {selectedFile?.title ? <div><p className="heading-tile"><b>Title</b></p><p>{selectedFile.title}</p></div> : '' } 
            {selectedFile?.description ? <div><p className="heading-tile"><b>Description</b></p><p>{selectedFile.description} </p></div> : '' }  
            {selectedFile?.tags?.length ? 
             <div><p className="heading-tile"><b>Tags</b></p>
              {selectedFile?.tags.map((tag, index) => {
                return (
                  <div className="tag">{tag}</div>
                )
              }) }
             </div>
             : ''
            }   
          </div>
             }
          <div className="search-label search-label-1">
            {fetchingResponse ? (
              <Loader
                variant="secondary"
                dimension="row"
                message="Fetching response..."
                size="sm"
              />
            ) : state?.dcsSearchResponse ? (
              <>
                {Array.isArray(state?.dcsSearchResponse?.res?.context) &&
                  state?.dcsSearchResponse?.res?.context?.length > 0 ? (
                  <>
                    <div>
                      <label>Question</label>
                      <p>{state?.dcsSearchResponse?.search}</p>
                    </div>
                    {/* <div>
                      <label>Response</label>
                      <>
                        <Textarea
                          rows={"4"}
                          cols={"30"}
                          readOnly
                          value={
                            state?.dcsSearchResponse?.res?.context?.at(1)
                              ?.content || ""
                          }
                          placeholder={"Search query"}
                        />
                      </>
                    </div> */}
                  </>
                ) : null}
                <div className="generated-summary-container">
                  <label>Generated Summary</label>
                  <ReactMarkdown
                    children={state?.dcsSearchResponse?.res?.text || ""}
                    remarkPlugins={[gfm]}
                  />
                </div>
              </>
            ) : null}
            <div className="summary-btn">
              <Button
                btnClasses="submit-btn"
                loading={fetchingResponse}
                onClick={handleSearch}
                text="Submit"
              />
              <Button
                btnClasses="back-btn"
                disabled={loading || fetchingResponse}
                onClick={handleBack}
                className="button button-1"
                bkbtn
                text={"Back"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DocumentSearch;
