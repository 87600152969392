import React from "react";
import TextSeeker from "../components/views/research/textSeeker";

const TextSeekerPage = () => {
  return (
    <>
      <TextSeeker />
    </>
  );
};

export default TextSeekerPage;
