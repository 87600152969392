export const getAllAssitants = async () => {
  return fetch(`${process.env.REACT_APP_API}/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getProfiles = async (type) => {
  return fetch(`${process.env.REACT_APP_API}/?type=${type}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getGeneralAssistantChat = async (question, context) => {
  const data = context
    ? { prompt: question, context: context }
    : { prompt: question };

  return fetch(`${process.env.REACT_APP_API}/default_gpt/`, {
    method: "POST",
    headers: {
      "Allow-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const getProducts = async () => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/product/`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const generateTags = async (name) => {
  const data = { name: name };
  return fetch(`${process.env.REACT_APP_BASE_URL}/product/tag/generate/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchSqlQueryAndData = async (search, metaData) => {
  const data = metaData
    ? { prompt: search, metadata: metaData }
    : { prompt: search };

  return fetch(`${process.env.REACT_APP_API}/text_2_sql/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const developerAssistantChat = async (question, context) => {
  const data = context
    ? { prompt: question, context: context }
    : { prompt: question };

  return fetch(`${process.env.REACT_APP_API}/developer_advisor/`, {
    method: "POST",
    headers: {
      "Allow-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const legalAssistantChat = async (question, context) => {
  const data = context
    ? { prompt: question, context: context }
    : { prompt: question };

  return fetch(`${process.env.REACT_APP_API}/legal_advisor/`, {
    method: "POST",
    headers: {
      "Allow-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => console.log(err));
};

export const uploadFile = async (file) => {
  const data = file;
  return fetch(`${process.env.REACT_APP_API}/data-process/`, {
    method: "POST",
    // headers: { "Content-Type": "application/json" },
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const populatedDrodownContent = async () => {
  return fetch(`${process.env.REACT_APP_API}/data-process/`, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const fetchDCSData = async (search, fileId) => {
  const data = {
    prompt: search,
    metadata: {
      file_id: fileId,
    },
  };

  return fetch(`${process.env.REACT_APP_API}/semantic_search/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const embeddText = async (context) => {
  const data = { text: context };
  return fetch(`${process.env.REACT_APP_API}/text-data-process/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const fetchTextSeeker = async (search) => {
  const data = { prompt: search };
  return fetch(`${process.env.REACT_APP_API}/text_2_search/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const fetchSummary = async (prompt) => {
  const data = { prompt: prompt };

  return fetch(`${process.env.REACT_APP_API}/summarizer_assistant/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const validateUser = async (user) => {
  const data = user;
  return fetch(`${process.env.REACT_APP_API}/db-connection/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
    });
};

export const addProduct = async (productName, tags) => {
  const data = {
    name: productName,
    tags: tags,
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/product/`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const editProductService = async (productName, tags, id) => {
  const data = {
    name: productName,
    tags: tags,
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/product/${id}/`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteProductService = async (id) => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/product/${id}/`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const uploadJsonFile = async (file) => {
  const data = file;
  return fetch(`${process.env.REACT_APP_BASE_URL}/swagger/add-swagger`, {
    method: "POST",
    body: data,
  })
    .then((response) => response.json())
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getFileList = async () => {
  return fetch(`${process.env.REACT_APP_BASE_URL}/swagger/add-swagger`, {
    method: "GET",
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const callSwagger = async (prompt, filePath, secretKey) => {
  const data = {
    prompt: prompt,
    json_file_path: filePath,
    metadata: {
      secret_key: secretKey,
    },
  };
  return fetch(`${process.env.REACT_APP_BASE_URL}/swagger/call-swagger`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((response) => {
      return response.json();
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
