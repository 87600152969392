import { Formik } from "formik";
import React, { useState } from "react";
import * as Yup from "yup";
import { addProduct, generateTags } from "../../../../services/apiService";
import Button from "../../../common/button";
import Input from "../../../common/input";
import Loader from "../../../common/loader";
import { toast } from "react-toastify";
import Chips from "react-chips";

const AddProduct = ({ setShow, setProducts, products }) => {
  const [isTagGenerated, setTagGenerated] = useState(false);

  const validate_product = Yup.object().shape({
    productName: Yup.string().required("Product Name is required"),
    // tags: Yup.array()
    //   .min(1, "Please select atleast one tag.")
    //   .nullable()
    //   .required("tags are required"),
  });

  const handleClick = (setFieldValue, productName) => {
    if (productName === "") return toast.error("Please enter product name");
    setFieldValue("loading", true);
    generateTags(productName)
      .then((res) => {
        setFieldValue("tags", res?.tags);
        toast.success("Tags created successfully");
        setTagGenerated(true);
        setFieldValue("loading", false);
      })
      .catch((err) => {
        toast.error(err);
        setTagGenerated(false);
        setFieldValue("loading", false);
      });
  };
  const addTags = (e, setFieldValue) => {
    setFieldValue("tags", e);
  };

  const initValues = {
    productName: "",
    tags: [],
    loading: false,
  };
  return (
    <Formik
      validationSchema={validate_product}
      initialValues={initValues}
      onSubmit={(values, { isSubmitting, setSubmitting, resetForm }) => {
        if (isSubmitting) return;
        addProduct(values?.productName, values?.tags)
          .then((res) => {
            toast.success("Product added successfully");
            const productClone = Array.isArray(products) ? [...products] : [];
            productClone.push(res);
            setTimeout(() => {
              setProducts(productClone);
              setSubmitting(false);
              resetForm(initValues);
              setTagGenerated(false);
              setShow(false);
            }, 1000);
          })
          .catch((err) => {
            toast.error(err);
            setSubmitting(false);
            resetForm(initValues);
            setTagGenerated(false);
            setShow(false);
          });
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <>
            <div className="form-user">
              <form onSubmit={handleSubmit}>
                <Input
                  name="productName"
                  placeholder="Product Name"
                  value={values?.productName}
                  error={errors && errors?.productName}
                  onChange={handleChange}
                />

                {!isTagGenerated ? (
                  values?.loading ? (
                    <>
                      <Loader
                        variant={"secondary"}
                        size="sm"
                        dimension="row"
                        message={"Generating tags..."}
                      />
                    </>
                  ) : (
                    <>
                      <Button
                        onClick={() =>
                          handleClick(setFieldValue, values?.productName)
                        }
                        text={"Generate Tags"}
                        loading={values?.loading}
                      />
                    </>
                  )
                ) : (
                  <>
                    <div>
                      <Chips
                        value={values?.tags}
                        onChange={(e) => addTags(e, setFieldValue)}
                        suggestions={values?.tags}
                        createChipKeys={[13, 32]}
                      />
                    </div>
                    <div className="mt-2">
                      <Button
                        loading={isSubmitting}
                        type="submit"
                        text={"save"}
                      />
                    </div>
                  </>
                )}
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default AddProduct;
