import React, { useState } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { embeddText } from "../../../../services/apiService";
import logo from "../../../../assets/Images/assist1.png";
import BackButton from "../../../common/backButton";
import ScreenInfo from "../../../common/info";
import Textarea from "../../../common/textarea";
import Button from "../../../common/button";

const AddContext = () => {
  const history = useHistory();
  const [context, setContext] = useState("");
  const [loading, setLoading] = useState(false);

  const handleClick = () => {
    if (context === "") {
      toast.info("Please enter context to search");
      return;
    }
    setLoading(true);
    embeddText(context)
      .then((res) => {
        toast.success(res?.message || "");
        setTimeout(() => {
          setLoading(false);
          history.push("/research/textseeker/response");
        }, 2500);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSkip = () => {
    history.push("/research/textseeker/response");
  };
  return (
    <>
      <section className="main main-summary">
        <BackButton path={`/research`} />
        <div className="summary-inner">
          <div className="summary-profile">
            <ScreenInfo img={logo} text="Add context to search" />
          </div>
          <div className="summart-form">
            <Textarea
              disabled={loading}
              rows="4"
              cols="50"
              name="summary"
              value={context}
              onChange={(e) => setContext(e.target.value)}
              placeholder="Please enter context"
            />
            <div className="skip-search-button">
              <Button
                onClick={handleClick}
                className="button"
                loading={loading}
                text={loading ? "Processing context..." : "Submit"}
              />
              <Button
                onClick={handleSkip}
                className="button"
                disabled={loading}
                text="Skip"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AddContext;
