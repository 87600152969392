import React, { useState } from "react";
import "./style.css";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { uploadFile } from "../../../../services/apiService";
import logo from "../../../../assets/Images/assist1.png";
import BackButton from "../../../common/backButton";
import Button from "../../../common/button";
import ScreenInfo from "../../../common/info";
import UploadFile from "../../../common/uploadFiles";

const DocUpload = () => {
  const history = useHistory();
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allowedExtension] = useState("pdf");

  const handleFileUpload = () => {
    if (!selectedFile) return toast.info("Please select a pdf file to upload");
    const formData = new FormData();
    formData.append("file", selectedFile);
    setLoading(true);
    uploadFile(formData)
      .then((res) => {
        setLoading(false);
        toast.success(res?.message || "");
        setTimeout(() => {
          history.push("/research/dcs/search");
          setSelectedFile(null);
        }, 500);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
        setSelectedFile(null);
      });
  };
  return (
    <section className="main main-summary">
      <BackButton path={`/research`} />
      <div className="dcs-inner">
        <div className="summary-profile">
          <ScreenInfo
            img={logo}
            text="Hey I am DCS assistant,please upload the document that you want to
            use as a context or simply enter skip button if you already have
            uploaded the file earlier. The file is considered valid if the filename has length four or more
            and does not startswith a number"
          />
        </div>
        <div className="upload-btn-wrapper">
          <UploadFile
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={loading}
            allowedExtention={allowedExtension}
          />
        </div>
        <div className="d-flex align-items-center justify-content-center pt-3">
          <Button
            disabled={loading}
            onClick={handleFileUpload}
            text={loading ? "Uploading file..." : "Upload"}
          />
          <Button
            onClick={() => history.push("/research/dcs/search")}
            btnClasses="button m-2 ms-2"
            disabled={loading}
            text={"Skip"}
          />
        </div>
      </div>
    </section>
  );
};

export default DocUpload;
