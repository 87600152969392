import React from "react";
import Spinner from "react-bootstrap/Spinner";

const Loader = ({
  size = "sm",
  variant,
  message,
  className = "",
  dimension = "column",
  animation = "border",
  messageColorClass = "text-secondary",
  centerAlign = true,
}) => {
  return (
    <div
      className={`p-1 d-flex flex-${dimension} ${
        centerAlign && "justify-content-center align-items-center"
      } ${
        !centerAlign && dimension === "row"
          ? "align-items-center"
          : "justify-content-center"
      } ${className}`}
    >
      <Spinner
        animation={animation}
        variant={variant}
        size={size}
        className={`${dimension === "row" ? "me-2" : ""}`}
      />
      {message && (
        <div
          style={{ fontSize: ".8rem" }}
          className={`bold ${messageColorClass}`}
        >
          {message}
        </div>
      )}
    </div>
  );
};
Loader.defaultProps = {
  variant: "primary",
};
export default Loader;
