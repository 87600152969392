import React from "react";
import "./style.css";
import logo from "../../../assets/icons/logo.svg";
const ScreenInfo = ({ img, text, profileClasses }) => {
  return (
    <div className={`profile ${profileClasses}`}>
      <img src={img} alt="assist" />
      <h6>{text}</h6>
    </div>
  );
};
ScreenInfo.defaultProps = {
  img: logo,
  text: "Info",
  profileClasses: "",
};
export default ScreenInfo;
