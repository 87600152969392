import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import Loader from "../../common/loader";
import { stateContext } from "../../../states/context";
import Heading from "../../common/heading";
import Checkbox from "../../common/checkbox";
import bot from "../../../assets/icons/bot.svg";
import research from "../../../assets/icons/research.svg";
import "./style.css";

const Home = () => {
  const { state, loading } = useContext(stateContext);
  const history = useHistory();

  return (
    <div className="container h-100">
      <div className="main">
        {loading ? (
          <div>
            <Loader
              dimension="row"
              variant="secondary"
              size="md"
              message="loading profiles..."
            />
          </div>
        ) : state?.assistants?.length > 0 ? (
          <>
            {" "}
            <div className="head">
              <Heading />
            </div>
            <div className="login-boxes">
              <Checkbox
                id="bot"
                img={bot}
                onClick={() => history.push("/chatbot")}
                headText={"Chat Bots"}
                subText={"Systango's Chatbot: Conversations Redefined"}
              ></Checkbox>
              <Checkbox
                id="research"
                img={research}
                onClick={() => history.push("/research")}
                headText={"Research"}
                subText={"Empowering Innovation through AI-guided Research"}
              ></Checkbox>
            </div>
          </>
        ) : (
          <h2>No data found</h2>
        )}
      </div>
    </div>
  );
};

export default Home;
