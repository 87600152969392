import React from "react";
import Tagbuilder from "../components/views/research/tagbuilder";

const TagbuilderPage = () => {
  return (
    <>
      <Tagbuilder />
    </>
  );
};

export default TagbuilderPage;
