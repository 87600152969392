import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import QueryAssistantChat from "./queryAssistant";

const QueryAssistant = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/chat`} />
        </Route>
        <Route exact path={`${path}/chat`}>
          <QueryAssistantChat />
        </Route>
      </Switch>
    </>
  );
};

export default QueryAssistant;
