import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Input from "../../../common/input";
import Button from "../../../common/button";
import { validateUser } from "../../../../services/apiService";

const QueryAssistantForm = ({ setShow }) => {
  const handleSkip = () => {
    localStorage.clear();
    setShow(false);
  };
  const validate_user = Yup.object().shape({
    host: Yup.string().required("host is required"),
    port: Yup.string().required("port is required"),
    dbname: Yup.string().required("dbname is required"),
    username: Yup.string().required("username is required"),
    password: Yup.string().required("password is required"),
  });
  const initValues = {
    host: "",
    port: "",
    dbname: "",
    username: "",
    password: "",
  };
  const setLocalStorage = (values) => {
    localStorage.clear();
    localStorage.setItem("username", values?.username);
    localStorage.setItem("host", values?.host);
    localStorage.setItem("port", values?.port);
    localStorage.setItem("dbname", values?.dbname);
    localStorage.setItem("password", values?.password);
  };
  return (
    <Formik
      validationSchema={validate_user}
      initialValues={initValues}
      onSubmit={(values, { setSubmitting }) => {
        setLocalStorage(values);
        const user = {
          db_name: values?.dbname,
          db_host: values?.host,
          db_port: values?.port,
          db_user: values?.username,
          db_password: values?.password,
        };
        validateUser(user)
          .then((res) => {
            if (res?.success) {
              toast.success(
                `You are now connected to the database named ${values?.dbname}`
              );
              setSubmitting(false);
              setShow(false);
              return;
            }
            toast.error(
              `connection to server at ${values?.host}, port ${values?.port} failed`
            );
            localStorage.clear();
            setSubmitting(false);
            setShow(false);
          })
          .catch((err) => {
            console.log(err);
            toast.error(err);
            localStorage.clear();
            setShow(false);
            setSubmitting(false);
          });
      }}
    >
      {({ values, errors, handleSubmit, handleChange, isSubmitting }) => {
        return (
          <>
            <div className="main main-1">
              <div className="form-user">
                <form onSubmit={handleSubmit}>
                  <Input
                    label="Host"
                    error={errors && errors?.host}
                    name="host"
                    type="text"
                    placeholder="Enter host"
                    value={values?.host}
                    onChange={handleChange}
                  />
                  <Input
                    label={"Port"}
                    error={errors && errors?.port}
                    name="port"
                    type="text"
                    placeholder="Enter port"
                    value={values?.port}
                    onChange={handleChange}
                  />
                  <Input
                    label={"Db Name"}
                    error={errors && errors?.dbname}
                    name="dbname"
                    type="text"
                    placeholder="Enter db name"
                    value={values?.dbname}
                    onChange={handleChange}
                  />
                  <Input
                    label={"Username"}
                    error={errors && errors?.username}
                    name="username"
                    type="text"
                    placeholder="Enter username"
                    value={values?.username}
                    onChange={handleChange}
                  />
                  <Input
                    label={"Password"}
                    error={errors && errors?.password}
                    name="password"
                    type="password"
                    placeholder="Enter password"
                    onChange={handleChange}
                    value={values?.password}
                  />

                  <div className="user-submit-btn">
                    <Button
                      type="submit"
                      loading={isSubmitting}
                      text={"Submit"}
                    />
                    <Button
                      onClick={handleSkip}
                      text={"Skip"}
                      disabled={isSubmitting}
                    />
                  </div>
                </form>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default QueryAssistantForm;
