import React, { useEffect, useState } from "react";
import "./style.css";
import { toast } from "react-toastify";
import { getProducts } from "../../../../services/apiService";
import Button from "../../../common/button";
import Input from "../../../common/input";
import CommonModal from "../../../common/modal";
import Table from "../../../common/table";
import AddProduct from "./addProduct";
import { COLUMNS } from "./column";
import BackButton from "../../../common/backButton";
import EditProduct from "./editProduct";
import DeleteProduct from "./deleteProduct";

const Tagbuilder = () => {
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(null);
  const [products, setProducts] = useState([]);
  const [editProduct, setEditProduct] = useState(null);
  const [filterProducts, setFilterProducts] = useState([...products]);

  useEffect(() => {
    setLoading(true);
    getProducts()
      .then((res) => {
        setProducts(res);
        setFilterProducts(res);
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }, [setFilterProducts]);

  useEffect(() => {
    const clone = Array.isArray(products) ? [...products] : [];
    setFilterProducts(clone);
  }, [products]);

  const searchProduct = (e) => {
    const { value } = e.target;
    if (value === "") {
      setFilterProducts([...products]);
    } else {
      const result = products?.filter((value) =>
        value.name.toLowerCase().includes(e.target.value.trim().toLowerCase())
      );
      setFilterProducts(result);
    }
  };
  return (
    <div className="tag-main container h-100">
      <div className="row d-flex search-back">
        <div className="col-md-4 d-flex">
          <BackButton path={"/research"} />
        </div>
        <div className="col-md-8  d-flex ">
          <div className="col-md-4"></div>
          <div className="col-md-8 d-flex search-addProduct">
            <Input
              placeholder="search product"
              disabled={loading}
              onChange={searchProduct}
            />
            <Button onClick={() => setShow(true)} text={"Add product"} />
          </div>
        </div>
      </div>
      <div className="m-2">
        <Table
          tableColumn={COLUMNS(
            setShowEdit,
            setEditProduct,
            setDeleteProduct,
            setShowDelete
          )}
          loading={loading}
          tableData={filterProducts || []}
        />
      </div>
      <CommonModal
        show={show}
        showClosebutton={false}
        title={"Add New Product"}
        closeHandler={() => setShow(false)}
      >
        <AddProduct
          setShow={setShow}
          setProducts={setProducts}
          products={products}
        />
      </CommonModal>
      <CommonModal
        show={showEdit}
        showClosebutton={false}
        title={"Edit Product"}
        closeHandler={() => setShowEdit(false)}
      >
        <EditProduct
          setShowEdit={setShowEdit}
          setEditProduct={setEditProduct}
          editProduct={editProduct}
          setProducts={setProducts}
          products={products}
        />
      </CommonModal>
      <CommonModal
        deleteModal
        show={showDelete}
        showClosebutton={false}
        title={`Delete '${deleteProduct?.name}'?`}
        closeHandler={() => setShowDelete(false)}
      >
        <DeleteProduct
          setShowDelete={setShowDelete}
          setDeleteProduct={setDeleteProduct}
          products={products}
          deleteProduct={deleteProduct}
          setProducts={setProducts}
        />
      </CommonModal>
    </div>
  );
};

export default Tagbuilder;
