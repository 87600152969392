import React from "react";
import DeveloperAssistant from "../components/views/chatbots/DeveloperAssistant";

const DeveloperAssistantPage = () => {
  return (
    <>
      <DeveloperAssistant />
    </>
  );
};

export default DeveloperAssistantPage;
