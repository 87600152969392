import React from "react";
import ResearchAssistants from "../components/views/research";

const ResearchPage = () => {
  return (
    <>
      <ResearchAssistants />
    </>
  );
};

export default ResearchPage;
