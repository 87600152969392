import React from "react";
import ChatbotAssistants from "../components/views/chatbots";

const ChatbotPage = () => {
  return (
    <>
      <ChatbotAssistants />
    </>
  );
};

export default ChatbotPage;
