import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import { stateContext } from "../../../../states/context";
import { fetchTextSeeker } from "../../../../services/apiService";
import Loader from "../../../common/loader";
import logo from "../../../../assets/Images/assist1.png";
import "./style.css";
import BackButton from "../../../common/backButton";
import Button from "../../../common/button";
import Input from "../../../common/input";
import ScreenInfo from "../../../common/info";

const TextToSearchResponse = () => {
  const history = useHistory();
  const { state, setState } = useContext(stateContext);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSearch = () => {
    if (search === "") return toast.info("Please enter text to search");
    setLoading(true);
    const prompt = search.trim();
    fetchTextSeeker(prompt)
      .then((res) => {
        setState((s) => ({ ...s, textSeeker: { query: search, res: res } }));
        setLoading(false);
        setSearch("");
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <section className="main main-summary">
      <BackButton path={`/research/textseeker/context`} state={`textSeeker`} />
      <div className="summary-inner">
        <div className="summary-profile">
          <ScreenInfo img={logo} text={"Response"} />
        </div>
        <div className="summart-form">
          <div className="search-label">
            <Input
              label={"Search"}
              type="text"
              placeholder="Search"
              name="search"
              disabled={loading}
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
          </div>
          <div className="search-label search-label-1">
            {loading ? (
              <Loader
                variant="secondary"
                dimension="row"
                size="sm"
                message="Fetching response..."
              />
            ) : state?.textSeeker ? (
              <>
                <div className="mb-2">
                  <label>Question</label>
                  <p>{state?.textSeeker?.query}</p>
                </div>
                <div>
                  <label>Response of your search</label>
                  {/* <textarea
                  readOnly
                  data-provide="markdown"
                  cols="70"
                  placeholder={"Search response"}
                  value={state?.textSeeker?.res?.text.replaceAll("\n", "\n\n")}
                ></textarea> */}
                  <ReactMarkdown
                    children={
                      state?.textSeeker?.res?.text.replaceAll("\n", "\n\n") ||
                      ""
                    }
                    remarkPlugins={[gfm]}
                  />
                </div>
              </>
            ) : null}

            <div className="summary-btn">
              <Button loading={loading} onClick={handleSearch} text="Search" />
              <Button
                onClick={() => {
                  setState((s) => ({ ...s, textSeeker: null }));
                  history.push("/research/textseeker/context");
                }}
                disabled={loading}
                bkbtn
                text={"Back"}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TextToSearchResponse;
