import React, { useContext, useEffect, useState } from "react";
import "./swagger.css";
import queryAssistantLogo from "../../../../assets/icons/queryAssistant.svg";
import BackButton from "../../../common/backButton";
import ChatInput from "../../../common/chatInput";
import { toast } from "react-toastify";
import CommonModal from "../../../common/modal";
import SecretKeyForm from "./secretKeyForm";
import { callSwagger } from "../../../../services/apiService";
import { stateContext } from "../../../../states/context";
import { useHistory } from "react-router-dom";
import HelpText from "../../../common/helptext";
import { getCurrentTime } from "../../../../services/timeService";
import SwaggerAssistantResponse from "./chatResponse";

const SwaggerChat = () => {
  const history = useHistory();
  const scrollableDivRef = React.useRef(null);
  const { state, setState } = useContext(stateContext);
  const [searchValue, setSearchValue] = useState({ message: "" });
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);

  const currentContext = state?.swaggerChat?.context
    ? state?.swaggerChat?.context
    : [];

  const addQuestion = (question) => {
    const _time = getCurrentTime();
    const initialContext = Array?.isArray(currentContext)
      ? [...currentContext]
      : [];
    initialContext.push({
      role: "user",
      text: question?.replaceAll("\n", "\n\n"),
      time: _time,
    });
    initialContext.push({
      role: "swagger",
      text: "Fetching response...",
      time: _time,
      context: [{ role: "application", content: "" }],
    });
    const swaggerChat = {
      context: initialContext,
    };
    setState((s) => ({ ...s, swaggerChat: swaggerChat }));
    setLoading(true);
    return initialContext;
  };

  const addAnswer = (answer, initContext, curl) => {
    const _time = getCurrentTime();
    if (!answer) return;
    const clone = [...initContext];
    const dataToAdd = {
      role: "swagger",
      time: _time,
      text: { ...answer },
      context: curl,
    };
    clone.splice(-1, 1, dataToAdd);
    const swaggerChat = {
      context: clone,
    };
    setState((s) => ({ ...s, swaggerChat: swaggerChat }));
    setLoading(false);
    scrollToBottom();
  };

  const handleChat = () => {
    const secret_key = localStorage.getItem("secret_key");
    const filePath = state?.selectedJsonFile?.file_path;
    if (searchValue?.message === "") return toast.info("Please enter prompt");
    if (filePath == null) {
      toast.error("Please select a json file");
      setTimeout(() => {
        history.push("/research/swagger/upload");
      }, 1200);
      return;
    }
    if (secret_key == null) {
      toast.info("Please enter a valid auth token");
      setTimeout(() => {
        setSearchValue({ message: "" });
        setShow(true);
      }, 1000);
      return;
    }
    const initialContext = addQuestion(searchValue?.message);
    setSearchValue({ message: "" });
    callSwagger(
      searchValue?.message,
      state?.selectedJsonFile?.file_path,
      secret_key
    )
      .then((response) => {
        if (response?.metadata?.status_code === 401) {
          const _time = getCurrentTime();
          localStorage.clear();
          toast.error("Token expired");
          const clone = [...initialContext];
          const dataToAdd = {
            role: "swagger",
            time: _time,
            text: "token expired",
            context: response?.context ? response?.context : [],
          };
          clone.splice(-1, 1, dataToAdd);
          const swaggerChat = {
            context: clone,
          };
          setState((s) => ({ ...s, swaggerChat: swaggerChat }));
          setTimeout(() => {
            setLoading(false);
            setShow(true);
          }, 1200);
          return;
        }
        const answer = response?.text ? response?.text : null;
        const curl = response?.context ? response?.context : [];
        addAnswer(answer, initialContext, curl);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const scrollToBottom = () => {
    scrollableDivRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    setShow(true);
  }, []);

  React.useEffect(() => {
    scrollToBottom();
  }, [currentContext]);

  return (
    <div className="chat-main">
      <BackButton path={"/research/swagger/upload"} state={`swaggerChat`} />
      {state?.swaggerChat ? (
        <div className="message">
          <div className="message-inner-wrapper">
            {state?.swaggerChat?.context?.map((value, index, array) => {
              return (
                <SwaggerAssistantResponse
                  key={`swagger-${index}-${value}`}
                  value={value}
                  loading={loading && index === array?.length - 1}
                />
              );
            })}
            <div ref={scrollableDivRef}></div>
          </div>
        </div>
      ) : (
        <div className="no-chat">
          <HelpText img={queryAssistantLogo} text="How can I help you?" />
        </div>
      )}

      <div className="chat-ask">
        <div className="search">
          <ChatInput
            handleChat={handleChat}
            onClick={handleChat}
            disabled={loading}
            type="text"
            name="swaggerChat"
            value={searchValue?.message}
            placeholder="Enter a prompt here"
            onChange={(e) => setSearchValue({ message: e.target.value })}
          />
        </div>
      </div>

      <CommonModal
        closeHandler={() => setShow(false)}
        show={show}
        title="Enter your secret key?"
      >
        <SecretKeyForm setShow={setShow} />
      </CommonModal>
    </div>
  );
};

export default SwaggerChat;
