import React from "react";
import "./style.css";
const HelpText = ({ img, text, helpClasses }) => {
  return (
    <div className={`help ${helpClasses}`}>
      <div className="help-img">
        <img src={img} alt="" />
      </div>
      <div className="help-content">
        <p>{text}</p>
      </div>
    </div>
  );
};

export default HelpText;
