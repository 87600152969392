import React from "react";
import GeneralAssistant from "../components/views/chatbots/GeneralAssistant";

const GeneralAssistantPage = () => {
  return (
    <>
      <GeneralAssistant />
    </>
  );
};

export default GeneralAssistantPage;
