import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import SwaggerChat from "./swaggerChat";
import UploadComponent from "./upload";

const Swagger = () => {
  const { path } = useRouteMatch();

  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/upload`} />
        </Route>
        <Route path={`${path}/upload`}>
          <UploadComponent />
        </Route>
        <Route path={`${path}/chat`}>
          <SwaggerChat />
        </Route>
      </Switch>
    </>
  );
};

export default Swagger;
