import React from "react";
import QueryAssistant from "../components/views/chatbots/QueryAssistant";

const QueryAssistantPage = () => {
  return (
    <>
      <QueryAssistant />
    </>
  );
};

export default QueryAssistantPage;
