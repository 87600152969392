import React from "react";
import "./swagger.css";
import { Formik } from "formik";
import * as Yup from "yup";
import Textarea from "../../../common/textarea";
import Button from "../../../common/button";

const SecretKeyForm = ({ setShow }) => {
  const validate_secret_key = Yup.object().shape({
    secret_key: Yup.string()
      .min(100, "Too Short!")
      .max(10000, "Too Long!")
      .required("Secret key is required"),
  });
  return (
    <Formik
      validationSchema={validate_secret_key}
      initialValues={{
        secret_key: "",
      }}
      onSubmit={(values, { setSubmitting }) => {
        localStorage.clear();
        localStorage.setItem("secret_key", values?.secret_key);
        setTimeout(() => {
          setSubmitting(false);
          setShow(false);
        }, 800);
      }}
    >
      {({ values, errors, handleSubmit, handleChange, isSubmitting }) => {
        return (
          <>
            <form onSubmit={handleSubmit}>
              <Textarea
                name={"secret_key"}
                onChange={handleChange}
                value={values?.secret_key}
                placeholder="Enter secret key"
                disabled={isSubmitting}
                error={errors?.secret_key && errors?.secret_key}
              />

              <div className="secret-key-submit-btn">
                <Button
                  loading={isSubmitting}
                  type={"submit"}
                  text={"Submit"}
                />
              </div>
            </form>
          </>
        );
      }}
    </Formik>
  );
};

export default SecretKeyForm;
