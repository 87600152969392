import React from "react";
import queryAssistantLogo from "../../../../assets/icons/queryAssistant.svg";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import Loader from "../../../common/loader";

const SwaggerAssistantResponse = ({ value, loading }) => {
  return (
    <>
      <div className="message-inner my-6">
        {value?.role === "user" && (
          <div className="message-inner-right">
            <div className="message-right">
              <div className="message-img">
                <img src={queryAssistantLogo} alt="icon" className="mx-auto" />
              </div>
              <p>{value?.text}</p>
              <div className="message-content"></div>
            </div>
            <p className="time time-right">{value?.time || ""}</p>
          </div>
        )}
      </div>
      <div className="message-inner">
        {value?.role === "swagger" && (
          <div className="message-inner-left">
            <div className="message-left">
              <div className="message-content">
                {loading ? (
                  <Loader
                    size="sm"
                    variant={"seconadary"}
                    message="Fetching response..."
                    dimension="row"
                  />
                ) : (
                  <>
                    <div>
                      {value?.context[0]?.content !== "" && (
                        <>
                          <h2>Curl:</h2>
                          <ReactMarkdown
                            children={value?.context[0]?.content}
                            remarkPlugins={[gfm]}
                          />
                        </>
                      )}
                      <pre>{`${JSON.stringify(value?.text, null, 2)}`}</pre>
                    </div>
                  </>
                )}
              </div>
            </div>
            <p className="time time-left">{value?.time || ""}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default SwaggerAssistantResponse;
