import { Badge } from "react-bootstrap";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";

export const COLUMNS = (
  setShowEdit,
  setEditProduct,
  setDeleteProduct,
  setShowDelete
) => {
  return [
    {
      Header: " Product Name",
      accessor: "name",
    },
    {
      Header: "tags",
      accessor: "tags",
      Cell: ({ value }) => {
        return value?.map((tags, index) => {
          return (
            <Badge
              key={`tags-${index}-product`}
              className="me-2 p-2"
              bg="secondary"
            >
              {tags}
            </Badge>
          );
        });
      },
    },
    {
      Header: "Actions",
      accessor: "actions",
      Cell: ({ row }) => {
        const handleEdit = () => {
          setEditProduct(row?.original);
          setShowEdit(true);
        };
        const handleDelete = () => {
          setDeleteProduct(row?.original);
          setShowDelete(true);
        };
        return (
          <div className="d-flex">
            <AiFillEdit onClick={handleEdit} />
            <AiFillDelete onClick={handleDelete} />
          </div>
        );
      },
    },
  ];
};
