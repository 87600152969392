import React from "react";
import "./style.css";
const Checkbox = ({ img, headText, subText, checkboxClasses, id, onClick }) => {
  return (
    <div onClick={onClick} className={`check-login ${checkboxClasses}`}>
      <input type="radio" name="login" value="bot" id={id} required="" />
      <label>
        <img src={img} alt="checkbox" />
        <h6>{headText || ""}</h6>
        <p>{subText || ""}</p>
      </label>
    </div>
  );
};

export default Checkbox;
