import React from "react";
import { Route, Switch } from "react-router-dom";
import ChatbotPage from "../pages/chatbotPage";
import DocContextSummarizerPage from "../pages/docContextSummarizerPage";
import DeveloperAssistantPage from "../pages/developerAssistantPage";
import GeneralAssistantPage from "../pages/generalAssistantPage";
import HomePage from "../pages/homePage";
import LegalassistantPage from "../pages/legalassistant";
import QueryAssistantPage from "../pages/queryAssistantPage";
import ResearchPage from "../pages/researchPage";
import TextSeekerPage from "../pages/textSeeker";
import TagbuilderPage from "../pages/tagbuilderPage";
import SwaggerPage from "../pages/swagger";

const Routes = () => {
  return (
    <>
      <Switch>
        <Route path="/" exact component={HomePage} />
        <Route path="/chatbot" exact component={ChatbotPage} />
        <Route path="/research" exact component={ResearchPage} />
        <Route
          path="/chatbot/generalassistant"
          component={GeneralAssistantPage}
        />
        <Route path="/chatbot/queryassistant" component={QueryAssistantPage} />
        <Route
          path="/chatbot/developerassistant"
          component={DeveloperAssistantPage}
        />
        <Route path="/chatbot/legalassistant" component={LegalassistantPage} />
        <Route path="/research/dcs" component={DocContextSummarizerPage} />
        <Route path="/research/textseeker" component={TextSeekerPage} />
        <Route path="/research/tagbuilder" component={TagbuilderPage} />
        <Route path="/research/swagger" component={SwaggerPage} />
      </Switch>
    </>
  );
};

export default Routes;
