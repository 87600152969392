import React, { useState } from "react";
import "./upload.css";
import { toast } from "react-toastify";

const UploadFile = ({
  selectedFile,
  setSelectedFile,
  loading,
  allowedExtention,
}) => {
  const [dragActive, setDragActive] = useState(false);
  const fileuploadRef = React.useRef();

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      if (isValidFile(e.dataTransfer.files[0])) {
        setSelectedFile(e.dataTransfer.files[0]);
        fileuploadRef.current.value = "";
      }
    }
  };

  const isValidFile = function (file) {
    const selectedExt = "." + file?.name?.split(".").reverse()[0];
    const isInvalidName = /[0-9]/.test(file?.name?.substring(0, 1));
    if (selectedExt === ".".concat(allowedExtention)) {
      if (
        allowedExtention === "pdf" &&
        !isInvalidName &&
        file?.name?.split(".")[0]?.length >= 4
      )
        return true;

      if (allowedExtention !== "pdf") return true;
    }
    setSelectedFile(null);
    fileuploadRef.current.value = "";
    toast.info(`Please upload a valid ${allowedExtention} file.`);
    return false;
  };

  const handleSelectFile = (fileEvent) => {
    fileEvent.preventDefault();
    const _files = fileEvent.target.files;
    if (_files && _files[0]) {
      if (isValidFile(_files[0])) {
        setSelectedFile(_files[0]);
        fileuploadRef.current.value = "";
      }
    }
  };
  return (
    <>
      <div
        className={`${dragActive && "active"} upload-btn-wrapper`}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <button className="btn upload-btn ">
          {selectedFile ? (
            <h6>
              <span>{selectedFile?.name}</span>
            </h6>
          ) : (
            <>
              <h6>
                Drag & drop file or <span>Browse</span>
              </h6>
              <p>Supported formates:{allowedExtention}</p>
            </>
          )}
        </button>
        <input
          title={selectedFile ? " " : "No file chosen"}
          disabled={loading}
          ref={fileuploadRef}
          type="file"
          name="myfile"
          onChange={handleSelectFile}
          accept={`*.${allowedExtention}`}
        />
      </div>
    </>
  );
};

export default UploadFile;
