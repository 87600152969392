import React from "react";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";
import queryAssistantLogo from "../../../../assets/icons/queryAssistant.svg";
import Loader from "../../../common/loader";

const QueryAssistantResponse = ({ data, loading }) => {
  return (
    <>
      <>
        <div className="message-inner my-6">
          <div className="message-inner-right">
            <div className="message-right">
              <div className="message-img">
                <img src={queryAssistantLogo} alt="icon" className="mx-auto" />
              </div>

              <div className="message-content">
                <ReactMarkdown
                  children={data?.query?.replaceAll("\n", "\n\n")}
                  remarkPlugins={[gfm]}
                />
              </div>
            </div>
            <p className="time time-right">{data?.time || ""}</p>
          </div>
        </div>
        <div className="message-inner">
          <div className="message-inner-left">
            <div className="message-left">
              <div className="message-content">
                {loading ? (
                  <>
                    {" "}
                    <Loader
                      dimension="row"
                      variant="secondary"
                      size="sm"
                      message="Fetching response..."
                    />
                  </>
                ) : data?.table?.length > 0 ? (
                  <>
                    {" "}
                    <h4>Query</h4>
                    <p>{data?.sql}</p>
                    <h4>Data</h4>
                    <table className="table table-hover table-bordered">
                      <thead>
                        <tr>
                          {Object.keys(data?.table[0])?.map((column, ind) => {
                            return (
                              <th key={`col-${column}-${ind}`}>{column}</th>
                            );
                          })}
                        </tr>
                      </thead>
                      <tbody>
                        {data?.table?.map((_, ind) => {
                          return (
                            <tr key={`query-${_}-${ind}-table`}>
                              {Object.values(_)?.map((data, ind) => {
                                return (
                                  <td key={`query-${data}-${ind}-table`}>
                                    {data}
                                  </td>
                                );
                              })}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </>
                ) : (
                  <ReactMarkdown
                    children={data?.sql?.replaceAll("\n", "\n\n")}
                    remarkPlugins={[gfm]}
                  />
                )}
              </div>
            </div>
            <p className="time time-left">{data?.time || ""}</p>
          </div>
        </div>
      </>
    </>
  );
};

export default QueryAssistantResponse;
