import React from "react";
import Swagger from "../components/views/research/swagger";

const SwaggerPage = () => {
  return (
    <>
      <Swagger />
    </>
  );
};

export default SwaggerPage;
