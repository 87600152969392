import React from "react";
import Header from "../header";
import "./style.css";

const LayoutComponent = ({ children }) => {
  return (
    <div className="wrapper">
      <Header />
      {children}
    </div>
  );
};

export default LayoutComponent;
