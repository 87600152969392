import React from "react";
import "./style.css";
import Textarea from "../textarea";
import send from "../../../assets/icons/send.svg";
import { toast } from "react-toastify";

const ChatInput = ({
  img,
  sreachClasses,
  onChange,
  disabled,
  onClick,
  name,
  value,
  handleChat,
  ...props
}) => {
  const handleKeyDown = (e) => {
    const keyCode = e.which || e.keyCode;
    if (keyCode === 13 && e.target.value === "") {
      if (!e.shiftKey) {
        e.preventDefault();
        return toast.error("Please enter prompt");
      }
      return e.preventDefault();
    }
    if (keyCode === 13 && !e.shiftKey) {
      e.preventDefault();
      handleChat();
    }
  };

  return (
    <div className={`search ${sreachClasses}`}>
      <button disabled={disabled} onClick={onClick} className="send-btn">
        <img src={send} alt="send" />
      </button>
      <Textarea
        name={name}
        value={value}
        disabled={disabled}
        onChange={onChange}
        placeholder="Enter a prompt here"
        textHeight
        id="message"
        inputWrapper="text-height"
        onKeyDown={handleKeyDown}
        {...props}
      />
    </div>
  );
};

ChatInput.defaultProps = {
  handleChat: () => {},
  onChange: () => {},
  disabled: false,
  name: "",
};

export default ChatInput;
