import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getAllAssitants } from "../../services/apiService";

export const stateContext = React.createContext({});
const ContextProvider = ({ children }) => {
  const [state, setState] = useState({
    chatbots: [],
    assistants: [
      {
        id: "tag_builder",
        name: "Tag Builder",
        description: "Add product of your choice, anytime",
        image_url: "/static/images/general_assistant.png",
      },
      {
        id: "swagger",
        name: "Swagger",
        description: "Swagger",
        image_url: "/static/images/general_assistant.png",
      },
    ],
    chat: null,
    legalChat: null,
    developerChat: null,
    textToSql: null,
    semanticSearch: null,
    summary: null,
    textSeeker: null,
    dcsFile: null,
    uploadedFileContents: null,
    dcsSearchResponse: null,
    jsonFileList: [],
    selectedJsonFile: null,
    swaggerChat: null,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getAllAssitants()
      .then((res) => {
        const clone = [...state?.assistants];
        const all_assistants = clone.concat(res);

        setState((prev) => ({ ...prev, assistants: all_assistants }));
        setLoading(false);
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
      });
  }, [setState]);
  return (
    <>
      <stateContext.Provider
        value={{
          state,
          setState,
          loading,
          setLoading,
        }}
      >
        {children}
      </stateContext.Provider>
    </>
  );
};

export default ContextProvider;
