import React, { useEffect, useState } from "react";
import "./swagger.css";
import { toast } from "react-toastify";
import BackButton from "../../../common/backButton";
import Button from "../../../common/button";
import UploadFile from "../../../common/uploadFiles";
import { getFileList, uploadJsonFile } from "../../../../services/apiService";
import { useContext } from "react";
import { stateContext } from "../../../../states/context";
import generalAssistantLogo from "../../../../assets/icons/generalAssistant.svg";
import Loader from "../../../common/loader";
import ScreenInfo from "../../../common/info";
import { useHistory } from "react-router-dom";

const UploadComponent = () => {
  const history = useHistory();
  const { state, setState } = useContext(stateContext);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [allowedExtension] = useState("json");
  const [loadingFile, setLoadingFile] = useState(false);
  const [isProcessingFile, setProcessingFile] = useState(false);

  const handleProceed = () => {
    if (!state?.selectedJsonFile) return toast.error("Please select a file");
    setProcessingFile(true);
    toast.success("File added successfully");
    setTimeout(() => {
      setProcessingFile(false);
      history.push("/research/swagger/chat");
    }, 1500);
  };

  const getFiles = () => {
    setLoadingFile(true);
    getFileList()
      .then((res) => {
        setState((s) => ({ ...s, jsonFileList: res }));
        setLoadingFile(false);
      })
      .catch((err) => {
        console.log(err);
        setState((s) => ({ ...s, jsonFileList: [] }));
        setLoadingFile(false);
      });
  };

  const handleFileUpload = () => {
    if (!selectedFile) return toast.info("Please select a json file to upload");
    const formData = new FormData();
    formData.append("file", selectedFile);
    setLoading(true);
    uploadJsonFile(formData)
      .then((res) => {
        setSelectedFile(null);
        setLoading(false);
        toast.success(res || "");
        getFiles();
      })
      .catch((err) => {
        toast.error(err);
        setLoading(false);
        setSelectedFile(null);
      });
  };

  const handleChange = (value) => {
    setState((s) => ({ ...s, selectedJsonFile: value }));
  };
  useEffect(() => {
    getFiles();
  }, [setState]);

  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="grid">
              <div className="col-12">
                <div className="backbutton-wrapper">
                  <BackButton path={"/research"} state={`selectedJsonFile`} />
                </div>
              </div>
              <div className="col-12">
                <ScreenInfo
                  img={generalAssistantLogo}
                  text={
                    "Hey I am Swagger assistant, please upload the document that you want to use as a context or simply choose the file form the below table if you already have uploaded the file earlier"
                  }
                />
              </div>
              <div className="file-selector">
                <UploadFile
                  selectedFile={selectedFile}
                  setSelectedFile={setSelectedFile}
                  loading={loading || loadingFile}
                  allowedExtention={allowedExtension}
                />

                {selectedFile && (
                  <div className="d-flex align-items-center justify-content-center mt-3">
                    <Button
                      disabled={loading || loadingFile}
                      onClick={handleFileUpload}
                      text={loading ? "Uploading file..." : "Upload"}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="custom-radio-selector-wrapper">
              {loadingFile ? (
                <Loader
                  variant="secondary"
                  dimension="row"
                  size="sm"
                  message={"Loading files..."}
                />
              ) : state?.jsonFileList?.length > 0 ? (
                <>
                  {state?.jsonFileList?.map((value, index) => {
                    return (
                      <div
                        key={`selected-file-${value}-${index}`}
                        className={`custom-radio-selector ${
                          value?.file_path ===
                            state?.selectedJsonFile?.file_path && "selected"
                        }`}
                      >
                        <label>
                          <input
                            value={value?.file_path}
                            onChange={() => handleChange(value)}
                            type="radio"
                            name="file"
                            id={`${value?.file_id}`}
                            checked={
                              value?.file_path ===
                              state?.selectedJsonFile?.file_path
                            }
                          />
                          <span className="radio-icon"></span>
                          <span>{value?.file_id}</span>
                          <span className="radio-selected"></span>
                        </label>
                      </div>
                    );
                  })}
                </>
              ) : (
                <p>Files not found</p>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white py-3">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center ">
            <p className="me-3 text-orange">Please select any one file</p>
            <Button
              onClick={handleProceed}
              text="Proceed"
              disabled={!state?.selectedJsonFile}
              loading={isProcessingFile}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadComponent;
