import LayoutComponent from "./components/common/layout";
import Routes from "./routes";

const App = () => {
  return (
    <LayoutComponent>
      <Routes />
    </LayoutComponent>
  );
};

export default App;
