import React, { useContext, useEffect, useState } from "react";
import "./style.css";
import queryAssistantLogo from "../../../../assets/icons/queryAssistant.svg";
import { toast } from "react-toastify";
import { stateContext } from "../../../../states/context";
import { fetchSqlQueryAndData } from "../../../../services/apiService";
import QueryAssistantResponse from "./chatResponse";
import BackButton from "../../../common/backButton";
import CommonModal from "../../../common/modal";
import QueryAssistantForm from "./userdetails";
import HelpText from "../../../common/helptext";
import ChatInput from "../../../common/chatInput";
import { getCurrentTime } from "../../../../services/timeService";

const QueryAssistantChat = () => {
  const scrollableDivRef = React.useRef(null);
  const { state, setState } = useContext(stateContext);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState({ message: "" });
  const [show, setShow] = useState(false);

  const scrollToBottom = () => {
    scrollableDivRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [setState?.textToSql]);

  const handleChat = () => {
    if (searchValue?.message === "") return toast.info("Please enter query");
    const isUserAdded = localStorage.getItem("username");
    const host = localStorage.getItem("host");
    const port = localStorage.getItem("port");
    const dbname = localStorage.getItem("dbname");
    const password = localStorage.getItem("password");
    const metaData = isUserAdded
      ? {
          db_user: isUserAdded,
          db_host: host,
          db_port: port,
          db_name: dbname,
          db_password: password,
        }
      : null;
    const preResponse = state?.textToSql?.res;
    const clone = Array?.isArray(preResponse) ? [...preResponse] : [];
    const _time = getCurrentTime();
    clone.push({
      query: searchValue?.message?.replaceAll("\n", "\n\n"),
      table: [],
      sql: "",
      time: _time,
    });
    const textToSql = {
      res: clone,
    };
    setState((pre) => ({
      ...pre,
      textToSql: textToSql,
    }));
    setLoading(true);
    fetchSqlQueryAndData(searchValue?.message, metaData)
      .then((res) => {
        const _time = getCurrentTime();
        const preRespone = state?.textToSql?.res;
        const clone = Array.isArray(preRespone) ? [...preRespone] : [];
        clone.push({
          query: searchValue?.message?.replaceAll("\n", "\n\n"),
          table: Array?.isArray(res?.text) ? res?.text : [],
          sql: Array.isArray(res?.context)
            ? res?.context.at(-1)?.content || ""
            : null,
          time: _time,
        });
        const textToSql = {
          res: clone,
        };
        setState((pre) => ({
          ...pre,
          textToSql: textToSql,
        }));
        scrollToBottom();
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
    setSearchValue({ message: "" });
  };

  useEffect(() => {
    setShow(true);
    return () => {
      localStorage.clear();
    };
  }, []);
  return (
    <div className="chat-main">
      <BackButton path={`/chatbot`} state={`textToSql`} />
      {state?.textToSql ? (
        <div className="message">
          <div className="message-inner-wrapper">
            {state?.textToSql?.res?.map((value, index, array) => {
              return (
                <div key={`${index}-text-query`}>
                  <QueryAssistantResponse
                    data={value}
                    loading={loading && index === array?.length - 1}
                  />
                </div>
              );
            })}
            <div ref={scrollableDivRef}></div>
          </div>
        </div>
      ) : (
        <div className="no-chat">
          <HelpText
            img={queryAssistantLogo}
            text="I am your query assistant, How can I help you?"
          />
        </div>
      )}
      <div className="chat-ask">
        <div className="search">
          <ChatInput
            handleChat={handleChat}
            onClick={handleChat}
            disabled={loading}
            type="text"
            name=" devchat"
            value={searchValue?.message}
            placeholder="Enter a prompt here"
            onChange={(e) => setSearchValue({ message: e.target.value })}
          />
        </div>
      </div>
      <div>
        <CommonModal
          closeHandler={() => setShow(false)}
          show={show}
          title="User Details"
        >
          <QueryAssistantForm setShow={setShow} />
        </CommonModal>
      </div>
    </div>
  );
};

export default QueryAssistantChat;
