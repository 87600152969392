export const clearState = (setState) => {
  setState((s) => ({
    ...s,
    chat: null,
    legalChat: null,
    developerChat: null,
    textToSql: null,
    semanticSearch: null,
    summary: null,
    textSeeker: null,
    dcsFile: null,
    uploadedFileContents: null,
    dcsSearchResponse: null,
    jsonFileList: [],
    selectedJsonFile: null,
    swaggerChat: null,
  }));
};
