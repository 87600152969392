import React from "react";
import DocumentContextSummarizer from "../components/views/research/summarizer";

const DocContextSummarizerPage = () => {
  return (
    <>
      <DocumentContextSummarizer />
    </>
  );
};

export default DocContextSummarizerPage;
