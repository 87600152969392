import React from "react";
import "./style.css";
const Input = ({
  value,
  onChange,
  placeholder,
  readOnly,
  inputClasses,
  label,
  inputWrapper,
  disabled,
  error,
  name,
  defaultValue,
  ...props
}) => {
  return (
    <div
      className={`inputwarpper ${inputWrapper && inputWrapper} ${
        disabled ? "disable-tag" : ""
      }`}
    >
      {label && <label>{label}</label>}
      <div className="formInput">
        <input
          className={`input ${inputClasses}`}
          name={name}
          type="text"
          disabled={disabled}
          onChange={onChange}
          readOnly={readOnly}
          {...props}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
        />
        <p className="error">{error}</p>
      </div>
    </div>
  );
};
Input.defaultProps = {
  placeholder: "Enter text",
  disabled: false,
  readOnly: false,
  label: false,
};

export default Input;
