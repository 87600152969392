import React from "react";
import "./style.css";
const Heading = ({ text, headingClasses }) => {
  return (
    <div className={`heading ${headingClasses}`}>
      <h1>{text}</h1>
    </div>
  );
};

Heading.defaultProps = {
  text: "How can we assist you?",
};

export default Heading;
