import React, { useState } from "react";
import { toast } from "react-toastify";
import { deleteProductService } from "../../../../services/apiService";
import Button from "../../../common/button";

const DeleteProduct = ({
  setShowDelete,
  setDeleteProduct,
  products,
  deleteProduct,
  setProducts,
}) => {
  const [loading, setLoading] = useState(false);
  const handleDelete = () => {
    setLoading(true);
    deleteProductService(deleteProduct?.id)
      .then((res) => {
        toast.success(`${deleteProduct?.name} deleted successfully`);
        const clone = Array.isArray(products) ? [...products] : [];
        const index = clone.findIndex((_) => _?.id === deleteProduct?.id);
        clone.splice(index, 1);
        setTimeout(() => {
          setProducts(clone);
          setDeleteProduct(null);
          setLoading(false);
          setShowDelete(false);
        }, 1000);
      })
      .catch((err) => {
        toast.error(err);
        setDeleteProduct(null);
        setLoading(false);
        setShowDelete(false);
      });
  };
  return (
    <div>
      <p>{`Are you sure , you want to delete ${deleteProduct?.name}`} ?</p>
      <div className="mt-1 d-flex">
        <Button text={"Delete"} onClick={handleDelete} loading={loading} />
        <Button
          btnClasses="me-2"
          text={"Cancel"}
          onClick={() => {
            setDeleteProduct(null);
            setShowDelete(false);
          }}
          disabled={loading}
        />
      </div>
    </div>
  );
};

export default DeleteProduct;
