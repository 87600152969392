import React from "react";
import "./style.css";
import { useTable, useSortBy } from "react-table";
import Loader from "../loader";

const Table = ({
  tableData,
  tableColumn,
  isSelectable,
  singleSelectable,
  loading,
  loadingMessage,
  ...rest
}) => {
  const tableProps = {
    columns: tableColumn,
    data: tableData,
  };
  if (isSelectable && singleSelectable) {
    tableProps.stateReducer = (newState, action) => {
      if (action.type === "toggleRowSelected") {
        newState.selectedRowIds = {
          [action.id]: action.value,
        };
      }
      return newState;
    };
  }
  const tableInstance = useTable(
    {
      ...tableProps,
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;
  return (
    <div className="table-wrapper" id="scrollableDiv">
      <table {...getTableProps()} className="tableFixHead">
        <thead>
          {headerGroups?.map((headerGroup, index) => (
            <tr key={index} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers?.map((column, index) => (
                <th
                  key={index}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? null
                        : null
                      : null}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {loading ? (
            <div className="no-data-found mt-4">
              <Loader
                size="sm"
                variant={"secondary"}
                message={loadingMessage}
                dimension="row"
              />
            </div>
          ) : rows.length === 0 ? (
            <>No data found</>
          ) : (
            rows?.map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} className="TABLE_ROW" {...row.getRowProps()}>
                  {row.cells?.map((cell, index) => {
                    return (
                      <td key={index} {...cell.getCellProps()}>
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
};
export default Table;
Table.defaultProps = {
  isSelectable: false,
  singleSelectable: false,
  loading: false,
  loadingMessage: "Loading...",
};
