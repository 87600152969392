import React, { useContext } from "react";
import "./style.css";
import { stateContext } from "../../../states/context";
import ProfileCard from "../../common/profilecard";
import { useHistory } from "react-router-dom";
import Heading from "../../common/heading";
import BackButton from "../../common/backButton";

const ChatbotAssistants = () => {
  const { state } = useContext(stateContext);
  const history = useHistory();

  const handleUserRole = (value) => {
    if (value?.id === "default_gpt") {
      history.push("/chatbot/generalassistant");
    }
    if (value?.id === "legal_advisor") {
      history.push("/chatbot/legalassistant");
    }
    if (value?.id === "developer_advisor") {
      history.push("/chatbot/developerassistant");
    }
    if (value?.id === "text_2_sql") {
      history.push("/chatbot/queryassistant/chat");
    }
  };
  return (
    <div className="container h-100">
      <BackButton path={`/`} />
      <div className="main">
        {state?.assistants?.length > 0 ? (
          <>
            <div className="head">
              <Heading />
            </div>
            <div className="login-boxes">
              {state?.assistants
                ?.filter(
                  (_) =>
                    _?.id !== "summarizer_assistant" &&
                    _?.id !== "semantic_search" &&
                    _?.id !== "text_2_search" &&
                    _?.id !== "swagger" &&
                    _?.id !== "tag_builder"
                )
                ?.map((value, index) => {
                  return (
                    <ProfileCard
                      key={`${index}-profile-chatbot`}
                      img={`${process.env.REACT_APP_BASE_URL}${value?.image_url}`}
                      headText={value?.name || ""}
                      subText={value?.description || ""}
                      onClick={() => handleUserRole(value)}
                    />
                  );
                })}
            </div>
          </>
        ) : (
          <h2>No data found</h2>
        )}
      </div>
    </div>
  );
};

export default ChatbotAssistants;
