import { Formik } from "formik";
import React from "react";
import * as Yup from "yup";
import { editProductService } from "../../../../services/apiService";
import Button from "../../../common/button";
import Input from "../../../common/input";
import { toast } from "react-toastify";
import Chips from "react-chips";

const EditProduct = ({
  setShowEdit,
  editProduct,
  setProducts,
  products,
  setEditProduct,
}) => {
  const _productDetails = { ...editProduct };
  const validate_product = Yup.object().shape({
    productName: Yup.string().required("Product Name is required"),
  });
  const addTags = (e, setFieldValue, values) => {
    setFieldValue("tags", e);
  };
  const initvalues = {
    id: _productDetails?.id,
    productName: _productDetails?.name || "",
    tags: _productDetails?.tags || [],
    loading: false,
  };
  return (
    <Formik
      validationSchema={validate_product}
      initialValues={initvalues}
      onSubmit={(values, { setSubmitting }) => {
        editProductService(values?.productName, values?.tags, values?.id)
          .then((res) => {
            toast.success("Product edited successfully");
            const productClone = Array.isArray(products) ? [...products] : [];
            const index = productClone.findIndex((_) => _?.id === values?.id);
            const data = {
              id: res?.id,
              name: res?.name,
              tags: res?.tags,
            };
            productClone.splice(index, 1, data);
            setTimeout(() => {
              setProducts(productClone);
              setEditProduct(null);
              setSubmitting(false);
              setShowEdit(false);
            }, 1000);
          })
          .catch((err) => {
            toast.error(err);
            setSubmitting(false);
            setShowEdit(false);
          });
      }}
    >
      {({
        values,
        setFieldValue,
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
      }) => {
        return (
          <>
            <div className="form-user">
              <form onSubmit={handleSubmit}>
                <Input
                  readOnly
                  name="productName"
                  placeholder="Product Name"
                  value={values?.productName}
                  error={errors && errors?.productName}
                />
                <div>
                  <Chips
                    value={values?.tags || []}
                    onChange={(e) => addTags(e, setFieldValue, values)}
                    suggestions={values?.tags}
                    createChipKeys={[13, 32]}
                  />
                </div>
                <div className="mt-2">
                  <Button loading={isSubmitting} type="submit" text={"save"} />
                </div>
              </form>
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default EditProduct;
