import React from "react";
import "./style.css";
import Button from "../button";
const ProfileCard = ({ img, headText, subText, assitClasses, onClick }) => {
  return (
    <div className={`assistant-inner ${assitClasses}`}>
      <div className="assistant-img">
        <img src={img} alt="assist-img" />
      </div>
      <h6>{headText}</h6>
      <p>{subText}</p>
      <Button onClick={onClick} text="Chat" />
    </div>
  );
};

export default ProfileCard;
