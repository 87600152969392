import React, { useContext } from "react";
import "./style.css";
import { Logo } from "../../../assets/icons";
import { useHistory, useLocation } from "react-router-dom";
import { stateContext } from "../../../states/context";
import { clearState } from "../../../services/stateService";

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const { setState } = useContext(stateContext);

  const gotoHomePage = (setState) => {
    if (location?.pathname === "/") return;
    clearState(setState);
    history.push("/");
  };

  return (
    <header>
      <div className="header-inner">
        <div className="header-img">
          <Logo onClick={() => gotoHomePage(setState)} />
        </div>
      </div>
    </header>
  );
};

export default Header;
