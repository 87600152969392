import React, { useContext } from "react";
import "./style.css";
import back from "../../../assets/icons/back.svg";
import { useHistory } from "react-router-dom";
import { stateContext } from "../../../states/context";

const BackButton = ({
  state,
  text = "Back",
  path,
  previousBtn,
  disabled,
  ...props
}) => {
  const history = useHistory();
  const { setState } = useContext(stateContext);

  const handleClick = () => {
    if (path === "/research/swagger/upload") localStorage.clear();
    setState((s) => ({
      ...s,
      [state]: null,
    }));
    history.push(path);
  };
  return (
    <div onClick={handleClick} className={`previousbtn ${previousBtn}`}>
      <button disabled={disabled}>
        <img src={back} alt="back" />
        {text}
      </button>
    </div>
  );
};

BackButton.defaultProps = {
  text: "Back",
  path: "/",
  state: "",
};
export default BackButton;
