import React from "react";
import LegalAssistant from "../components/views/chatbots/LegalAssistant";

const LegalassistantPage = () => {
  return (
    <>
      <LegalAssistant />
    </>
  );
};

export default LegalassistantPage;
