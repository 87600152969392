import React from "react";
import { Modal } from "react-bootstrap";
import "./style.css";

const CommonModal = ({
  deleteModal,
  show,
  closeHandler,
  children,
  size,
  showClosebutton,
  showHeader,
  title,
  scrollable,
}) => {
  return (
    <Modal
      size={size}
      show={show}
      onHide={closeHandler}
      className="bg-gradient text-dark"
      scrollable={scrollable}
    >
      <div className="modal-wrapper">
        {showHeader && (
          <Modal.Header
            className="m-0 p-0 ps-3 pe-3 pt-3"
            closeButton={showClosebutton}
          >
            <h6 className="heading">{title}</h6>
          </Modal.Header>
        )}
        <Modal.Body>{children}</Modal.Body>
      </div>
    </Modal>
  );
};

CommonModal.defaultProps = {
  size: "md",
  showHeader: true,
  title: "",
  scrollable: true,
  showClosebutton: true,
  deleteModal: false,
};

export default CommonModal;
