import React from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import DocUpload from "./docUpload";
import DocumentSearch from "./searchquery";

const DocumentContextSummarizer = () => {
  const { path } = useRouteMatch();
  return (
    <>
      <Switch>
        <Route exact path={`${path}`}>
          <Redirect to={`${path}/upload`} />
        </Route>
        <Route path={`${path}/upload`}>
          <DocUpload />
        </Route>
        <Route path={`${path}/search`}>
          <DocumentSearch />
        </Route>
      </Switch>
    </>
  );
};

export default DocumentContextSummarizer;
