import React, { useContext, useState } from "react";
import "./style.css";
import general from "../../../../assets/icons/generalAssistant.svg";
import { toast } from "react-toastify";
import { stateContext } from "../../../../states/context";
import { getGeneralAssistantChat } from "../../../../services/apiService";
import GeneralAssistantChat from "./chatBox";
import BackButton from "../../../common/backButton";
import { getCurrentTime } from "../../../../services/timeService";
import ChatInput from "../../../common/chatInput";
import HelpText from "../../../common/helptext";

const GeneralAssistant = () => {
  const scrollableDivRef = React.useRef(null);
  const { state, setState } = useContext(stateContext);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState({ message: "" });
  const currentContext = state?.chat?.context ? state?.chat?.context : [];

  React.useEffect(() => {
    scrollToBottom();
  }, [currentContext]);

  const addQuestion = (question) => {
    const _time = getCurrentTime();
    const initialContext = Array?.isArray(currentContext)
      ? [...currentContext]
      : [];
    initialContext.push({
      role: "user",
      content: question?.replaceAll("\n", "\n\n"),
      time: _time,
    });
    initialContext.push({
      role: "assistant",
      content: "Fetching response...",
      time: _time,
    });
    const chat = {
      context: initialContext,
      text: "Hello! How can I assist you today?",
    };
    setState((s) => ({ ...s, chat: chat }));
    setLoading(true);
    return initialContext;
  };

  const addAnswer = (answer, initContext) => {
    const _time = getCurrentTime();
    if (!answer) return;
    const clone = [...initContext];
    const dataToAdd = {
      ...answer,
      time: _time,
    };
    clone.splice(-1, 1, dataToAdd);
    const chat = {
      context: clone,
      text: "Hello! How can I assist you today?",
    };
    setState((s) => ({ ...s, chat: chat }));
    setLoading(false);
    scrollToBottom();
  };

  function removeTimeKey(value) {
    const _ = value;
    return {
      role: _?.role,
      content: _?.content,
    };
  }

  const scrollToBottom = () => {
    scrollableDivRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const handleChat = async (e) => {
    if (searchValue?.message === "") return toast.info("Please enter prompt");
    const initialContext = addQuestion(searchValue?.message);
    setSearchValue({ message: "" });
    const contextToSend = [...currentContext]?.map(removeTimeKey);
    getGeneralAssistantChat(searchValue?.message, contextToSend)
      .then((res) => {
        const answer = Array.isArray(res?.context) ? res?.context.at(-1) : null;
        addAnswer(answer, initialContext);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };
  return (
    <div className="chat-main">
      <BackButton path={`/chatbot`} state={`chat`} />
      {state?.chat ? (
        <div className="message">
          <div className="message-inner-wrapper">
            {state?.chat?.context?.map((value, index) => {
              return (
                <GeneralAssistantChat
                  key={`chat-${index}-general`}
                  value={value}
                />
              );
            })}
            <div ref={scrollableDivRef}></div>
          </div>
        </div>
      ) : (
        <div className="no-chat">
          <HelpText
            img={general}
            text="I am your general assistant, How can I help you?"
          />
        </div>
      )}
      <div className="chat-ask">
        <div className="search">
          <ChatInput
            handleChat={handleChat}
            onClick={handleChat}
            disabled={loading}
            type="text"
            name="chat"
            value={searchValue?.message}
            placeholder="Enter a prompt here"
            onChange={(e) => setSearchValue({ message: e.target.value })}
          />
        </div>
      </div>
    </div>
  );
};

export default GeneralAssistant;
