import React from "react";
import "./style.css";
import developerLogo from "../../../../assets/icons/developerAssistant.svg";
import ReactMarkdown from "react-markdown";
import gfm from "remark-gfm";

const DeveloperChatResponse = ({ value }) => {
  return (
    <>
      <div className="message-inner my-6">
        {value?.role === "user" && (
          <div className="message-inner-right">
            <div className="message-right">
              <div className="message-img">
                <img src={developerLogo} alt="icon" className="mx-auto" />
              </div>

              <div className="message-content">
                <ReactMarkdown
                  children={value?.content?.replaceAll("\n", "\n\n")}
                  remarkPlugins={[gfm]}
                />
              </div>
            </div>
            <p className="time time-right">{value?.time || ""}</p>
          </div>
        )}
      </div>
      <div className="message-inner">
        {value?.role === "assistant" && (
          <div className="message-inner-left">
            <div className="message-left">
              <div className="message-img">
                <img src={developerLogo} alt="bot" className="mx-auto" />
              </div>

              <div className="message-content">
                <ReactMarkdown
                  children={value?.content?.replaceAll("\n", "\n\n")}
                  remarkPlugins={[gfm]}
                />
              </div>
            </div>
            <p className="time time-left">{value?.time || ""}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default DeveloperChatResponse;
