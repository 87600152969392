import React from "react";
import "./style.css";
const Textarea = ({
  placeholder,
  value,
  onChange,
  name,
  disabled,
  readOnly,
  textHeight,
  id,
  onKeyDown,
  textClasses,
  rows,
  label,
  inputWrapper,
  cols,
  error,
  ...props
}) => {
  return (
    <div className={`inputwarpper ${inputWrapper}`}>
      <textarea
        className={`${textHeight ? "textarea-custom" : ""} ${textClasses}`}
        id={id}
        readOnly={readOnly}
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        onKeyDown={onKeyDown}
        rows={rows}
        cols={cols}
      ></textarea>
      {error && <p className="error">{error}</p>}
    </div>
  );
};
Textarea.defaultProps = {
  rows: "4",
  cols: "50",
  disabled: false,
  value: "",
  onChange: () => {},
  error: false,
};

export default Textarea;
