import React from "react";
import Loader from "../loader";
import "./style.css";
const Button = ({
  text,
  bkbtn = false,
  btnClasses,
  loading = false,
  variant = "secondary",
  onClick,
  message,
  disabled,
  type,
  children,
  ...props
}) => {
  return (
    <button
      type={type}
      onClick={loading || disabled ? () => {} : onClick}
      className={`commanbtn ${bkbtn ? "commanbtn1" : ""} ${
        disabled ? "disable-tag" : ""
      } ${btnClasses ? btnClasses : ""}`}
    >
      {loading ? (
        <Loader
          size="sm"
          dimension="row"
          variant={variant}
          message={message ? message : "Loading..."}
        />
      ) : (
        <>
          {children}
          {text}
        </>
      )}
    </button>
  );
};

Button.defaultProps = {
  text: "button",
  variant: "secondary",
  bkbtn: false,
  loading: false,
  message: "",
  disabled: false,
};
export default Button;
